import React from "react"
import Log from "./../../../templates/log.js"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Caption, Extern, FirstP, Emoji } from "./../../../components/helpers.js"
import mbldc from '../../../img/mbldc.gif'

export default ({ data, pageContext }) => {

    const content = <>

        <FirstP>With pride I can present the first hardware version of my own BLDC controller which I named the MBLDC for obvious reasons. In the last update I gave my reasoning for the different
            components I selected, as well as the wish to keep it small, and to keep it a one-sided PCB. In this post I can show you my design, the assembled PCB and even a spinning motor!
        </FirstP>
                 
        <Img fadeIn={false} fluid={data.img1.childImageSharp.fluid} alt="Clock mode" />
        <Caption>The puzzle that is PCB routing</Caption>        
        
        <p>
            After a lot of digging in the various datasheets I came up with the schematic that is available on <Extern href="https://github.com/maakbaas/mbldc-hardware/blob/main/schematic.pdf">GitHub</Extern>. 
            And with that the routing game could start. This is actually quite relaxing, like solving a Sudoku or a jigsaw puzzle. Because I wanted to keep the board as small as possible 
            I opted for a 4-layer PCB, with dedicated power and ground layers. On top of that I made a large power plane across the PCB on the bottom signal layer. This to make sure that enough
            current can be transfered from the input side to the motor side. 
            </p>
            <p>
            Next to that I also tried to calculate the number of vias needed for current transfer to the MOSFETs. 
            With all the components in place the final dimensions of the design are 62x40mm which I am quite happy with. 
            The most challenging part to route was between the MCU and the gate driver
            as you can see on the picture above. The final design is shown below. I am also a big fan of the 3D view in KiCad, and as you can see it is a great indication of what your actual PCB will look like.
        </p>

        <Img fadeIn={false} fluid={data.img2.childImageSharp.fluid} alt="Calendar mode" />
        <Caption>The power of computer aided design</Caption>    

        <h3>The hardware</h3>

        <p>
            I am getting quite some practice with PCB assembly recently, because of the sales of my Bluetooth module. I am happy to report that my motor skills are improving, and I 
            no longer require alcohol to keep my tweezers steady. I have also upgraded from a skillet to a pizza oven, and thanks to those things this PCB turned out right the first time <Emoji e="🍕" />.
        </p>

        <p>The careful observer will notice three missing components near the logo. These were supposed to be capacitors for filtering the current measurements, but it turned out that I ordered 0306 components instead of 0603.
            I did not even know these existed. Well, that will be a nice test to find out if those caps are actually needed. This is actually something I plan to do with more components such as the external clock, to see if I can further simplify the board.
        </p>

        <Img fadeIn={false} style={{marginBottom: '1em'}} fluid={data.img5.childImageSharp.fluid} alt="MBLDC with motor" />
        <Img fadeIn={false} style={{marginBottom: '1em'}} fluid={data.img6.childImageSharp.fluid} alt="BLDC controller with motor" />
        <Img fadeIn={false} fluid={data.img3.childImageSharp.fluid} alt="The MBLDC" />
        
        <Caption>The assembled MBLDC</Caption>    

        <h3>First Tests</h3>

        <p>
            I started by connecting my debugger to see if the board would actually talk to me. Since there is also a status LED on-board I could start with a blinky, and was very relieved to see that it actually worked.
            For this project I completely skipped the prototyping phase, and I have never created any hardware with a STM32, or any of the other components on this board basically.
            Therefore I had set my expectations for the PCB actually working quite low <Emoji e="😅" />. 
        </p>  

        <p>
            Each new test was a new small success. The LEDs work, the crystal works, the analog inputs work to read in the various voltages, currents and temperatures and the SPI communication with the gate driver all work too.
            Would that mean I could actually spin a motor with this thing?
        </p>

        <Img fadeIn={false} fluid={data.img7.childImageSharp.fluid} alt="Debugger" />
        <Caption>Connected to my debugger for programming and online calibration</Caption>    

        <p>
            Once I validated that all functions are working individually I made my first attempts at spinning the motor. I set up my PWM timers, and started out by sending a low duty ratio
            at a fixed speed, and lo and behold, the motor started moving. Right now I have a partial implementation of back-emf sensing, and I can spin the motor at various speeds by setting
            a different duty ratio. The commutation switching points are then based on the back-emf, but much more work is needed here.
        </p>

        <img src={mbldc} style={{ width: '100%' }} alt="It moves" />
        <Caption>Here is proof that it actually works!</Caption>    

        <p>
            In the next post I will go in more detail on the software development for the motor controller. In the future the software will also be made available on GitHub.
        </p>

        

    </>;

    return (<Log pageContext={pageContext}>{content}</Log>);
}

export const query = graphql`
{
    img1: file(relativePath: { eq: "mbldc_pcb.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img2: file(relativePath: { eq: "mbldc_render.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img3: file(relativePath: { eq: "DSCF2223.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img5: file(relativePath: { eq: "DSCF2229.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img6: file(relativePath: { eq: "DSCF2231.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img7: file(relativePath: { eq: "DSCF2235.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`